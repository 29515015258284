<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadConfigsConceptos()"
              >
                <v-row>
                  <!-- Nombre concepto -->
                  <v-col cols="12" sm="6" md="5" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Nombre"
                      v-model="nombreConcepto"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Vigentes -->
                  <v-col cols="12" sm="3" md="2" class="py-0">
                    <v-switch
                      class="mt-1"
                      v-model="soloConceptosVigentes"
                      :disabled="todosConceptos"
                      label="Solo vigentes"
                      @change="switchFiltroSoloVigentes()"
                      dense
                      outlined
                    ></v-switch>
                  </v-col>
                  <!-- Todos -->
                  <v-col cols="12" sm="3" md="2" class="py-0">
                    <v-switch
                      class="mt-1"
                      v-model="todosConceptos"
                      :disabled="soloConceptosVigentes"
                      label="Todos"
                      dense
                      @change="switchFiltroTodosConceptos()"
                      outlined
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    :disabled="!isFormValid"
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="configsConceptos"
            class="elevation-1"
            :search="search"
            :loading="loading"
            item-key="liqDesConcId"
          >
            <template v-slot:[`item.esVigente`]="{ item }">
              <v-icon v-if="item.esVigente" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" align="center">
                    <v-btn
                      color="primary"
                      outlined
                      v-if="canConfigComisiones"
                      @click="goToConfigComisiones()"
                    >
                      Comisiones
                    </v-btn>
                  </v-col>
                  <v-col cols="1" align="end">
                    <v-btn
                      color="primary"
                      class="to-right"
                      v-if="canCreate"
                      @click="openModalEditConcepto()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEditConcepto(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar concepto</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteConcepto(item.liqDesConc_id)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar concepto</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="45%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditConceptoDesregulacion
        :conceptoDesregulacion="conceptoDesregulacion"
        @closeAndReload="closeAndReload"
      ></EditConceptoDesregulacion>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDeleteConcepto()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import EditConceptoDesregulacion from "@/components/modules/convenios/procesosDesregulacion/EditConceptoDesregulacion.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfigProcesosDesregulacion",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    FiltersSelected,
    EditConceptoDesregulacion,
    Ayuda
  },
  created() {
    this.loadConfigsConceptos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  data: vm => ({
    routeToGo: "ConsultaProcesosDesregulacion",
    title: enums.titles.CONFIG_CONCEPTO_DESREGULACION,
    search: "",
    titleDelete: "¿Desea eliminar el concepto?",
    rules: rules,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    isFormValid: true,
    loading: false,
    filtersApplyed: [],
    showFilters: true,
    configsConceptos: [],
    headers: [
      {
        text: "Concepto",
        align: "start",
        value: "liqDesConc_Nom",
        sortable: false
      },
      {
        text: "Proceso asociado",
        align: "start",
        value: "nombre",
        sortable: false
      },
      {
        text: "Vigente",
        align: "center",
        value: "esVigente",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    showDeleteModal: false,
    nombreConcepto: null,
    soloConceptosVigentes: false,
    todosConceptos: true,
    allowedActions: null,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    canConfigComisiones: false,
    openModalEdit: false,
    conceptoDesregulacion: null,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.CONFIG_CONCEPTO_DESREGULACION
  }),
  methods: {
    ...mapActions({
      getConfiguracionesConceptos: "convenios/getConfiguracionesConceptos",
      deleteConfigConcepto: "convenios/deleteConfigConcepto",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_CONFIG_CONCEPTOS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_CONFIG_CONCEPTOS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_CONFIG_CONCEPTOS:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_COMISIONES_CONCEPTOS:
            this.canConfigComisiones = true;
            break;
          default:
            break;
        }
      });
    },
    async loadConfigsConceptos() {
      this.configsConceptos = [];
      this.loading = true;
      this.customizeFiltersApplied();
      try {
        const conceptos = await this.getConfiguracionesConceptos();
        if (this.nombreConcepto != null) {
          conceptos.forEach(x => {
            const filtroNombre = x.liqDesConc_Nom
              .toLowerCase()
              .includes(this.nombreConcepto.toLowerCase());
            if (this.soloConceptosVigentes) {
              if (x.esVigente && filtroNombre) this.configsConceptos.push(x);
            } else {
              if (filtroNombre) this.configsConceptos.push(x);
            }
          });
        } else if (this.soloConceptosVigentes) {
          this.configsConceptos = conceptos.filter(x => x.esVigente);
        } else this.configsConceptos = conceptos;
        this.showFilters = false;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.nombreConcepto) {
        this.filtersApplyed.splice(0, 1, {
          key: "nombreConcepto",
          label: "Nombre",
          model: this.nombreConcepto
        });
      }
      if (this.soloConceptosVigentes) {
        const filtroVigencia = "Solo vigentes";
        this.filtersApplyed.splice(1, 1, {
          key: "soloConceptosVigentes",
          label: "Vigencia",
          model: filtroVigencia
        });
      } else if (this.todosConceptos) {
        const filtroVigencia = "Todos";
        this.filtersApplyed.splice(1, 1, {
          key: "todosConceptos",
          label: "Vigencia",
          model: filtroVigencia
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    deleteConcepto(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDeleteConcepto() {
      const response = await this.deleteConfigConcepto(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadConfigsConceptos();
      }
    },
    switchFiltroSoloVigentes() {
      if (!this.soloConceptosVigentes) this.todosConceptos = true;
    },
    switchFiltroTodosConceptos() {
      if (!this.todosConceptos) this.soloConceptosVigentes = true;
    },
    openModalEditConcepto(item) {
      this.conceptoDesregulacion = item;
      this.openModalEdit = true;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadConfigsConceptos();
    },
    goToConfigComisiones() {
      this.$router.push({
        name: "ConfigComisionesConcDesregulacion"
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
